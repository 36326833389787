import React from "react";
import "./style.scss";

const DashBoard = () => {
  return (
    <>
      {/* <h3 className="page_title">Dashboard</h3> */}
      <div>
        <div>
          <p>guHRoo Admins and Managers,</p>
        </div>
        <div>
          <p>
            We are excited to announce the release of a second iteration of the
            timekeeping module to go live on Monday, October 14th!{" "}
          </p>
        </div>
        <div>
          <p>
            After the first iteration, we have gathered feedback and suggestions
            from end users and are making a couple of very important updates.
            Linked is a short video displaying the changes summarized below!
          </p>
        </div>
        <div>
          <a href="/dashboard">
            Please take a few moments to watch this brief video on the updates
            summarized below
          </a>
        </div>
        <p></p>
        <div>
          <b>
            Revision to the "Pending, Approved, and Rejected" tabs in the
            timecards
          </b>
          <p>
            Timecards will now be reflected as Pending, Approved or Rejected
            right from the main timekeeping module screen under the Status
            column. Multi approval of timecards is able to occur directly from
            this screen.
          </p>
        </div>
        <div>
          <b>Timecard edit requests are coming back</b>
          <p>
            We are adding timecard edit requests back in to the timekeeping
            module. When employees add time manually on to their timecard for a
            full day without clocking in/out or if they are making an adjustment
            to existing punches, this will trigger a timecard edit request which
            then should be approved by the manager/admin. This will operate
            almost exactly the same way as before with some minor UI changes.
          </p>
        </div>{" "}
        <div>
          <b>Hour totals on employee timecards</b>
          <p>
            We have added total columns in to the employee's timecard for
            increased visibility on potential OT in a given work week. Personal
            Hours will include any policy-related hours except for Holiday which
            are explicitly separated.
          </p>
        </div>
        <div>
          <p>Thank you for the opportunity to serve you!</p>
        </div>
        <div>
          <p>The guHRoo Family</p>
        </div>
      </div>
      {/* <?php
echo "<div>";
echo "<div>";
echo "<p>guHRoo Admins and Managers,</p>";
echo "</div>";
echo "<div>";
echo "</div>";
echo "<div>";
echo "<p>";
    echo "We are excited to announce the release of a second iteration of the
    timekeeping module to go live on Monday, October 14th!";
    echo "</p>";
    echo "</div>";
    echo"<div>";
    echo"</div>";
    echo"<div>";
    echo"<p>";
    echo "After the first iteration, we have gathered feedback and suggestions
    from end users and are making a couple of very important updates.
    Linked is a short video displaying the changes summarized below!";
    echo "</p>";
    echo "</div>";
    echo "<div>";
    echo "<a href='https://www.loom.com/share/4d5be757f45e42fda52816c9185b197c?sid=1228a646-a130-4c71-b40c-33b0d558f878'>
    Please take a few moments to watch this brief video on the updates
    summarized below";
    echo"</a>";
    echo "</div>";
    echi "<p>";
    echi "</p>";
    echo"<div>";
    echo "</div>";
    echo "<div>";
    echo "<b>";
    echo "Revision to the 'Pending, Approved, and Rejected' tabs in the
    timecards";
    echo "</b>";
    echo "<p>
    Timecards will now be reflected as Pending, Approved or Rejected
    right from the main timekeeping module screen under the Status
    column. Multi approval of timecards is able to occur directly from
    this screen.";
    echo"</p>";
    echo"</div>";
    echo"<div>";
    echo "</div>";
    echo "<div>";
    echo "<b>Timecard edit requests are coming back</b>";
    echo"<p>";
    echo"We are adding timecard edit requests back in to the timekeeping
    module. When employees add time manually on to their timecard for a
    full day without clocking in/out or if they are making an adjustment
    to existing punches, this will trigger a timecard edit request which
    then should be approved by the manager/admin. This will operate
    almost exactly the same way as before with some minor UI changes.";
    echo"</p>";
    echo"</div>";
    echo "<div>";
    echo "</div>";
    echo"<div>";
    echo"<b>Hour totals on employee timecards</b>";
    echo"<p>";
    echo "We have added total columns in to the employee's timecard for
    increased visibility on potential OT in a given work week. Personal
    Hours will include any policy-related hours except for Holiday which
    are explicitly separated.";
    echo"</p>";
    echo"</div>";
    echo"<div>";
    echo "</div>";
    echo"<div>";
    echo"<p>Thank you for the opportunity to serve you!</p>";
    echo"</div>";
    echo"<div>";
    echo"</div>";
    echo"<div>";
    echo"<p>The guHRoo Family</p>";
    echo"</div>";
    echo"</div>";
?> */}
    </>
  );
};

export default DashBoard;
